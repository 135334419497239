import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Admin from '../views/Admins/Admin.vue';
import AdminHome from '../views/Admins/AdminHome.vue';
import Clientes from '../views/Clientes/Clientes.vue';
import Sucursales from '../views/Sucursales/RegistrarSucursales.vue';
import VerSucursales from '../views/Sucursales/VerSucursales.vue';
import RegistrarUsuario from '../views/Usuarios/RegistrarUsuario.vue';
import VerUsuarios from '../views/Usuarios/VerUsuarios.vue';
import EditarSucursal from '../views/Sucursales/EditarSucursal.vue';
import EditarUsuario from '../views/Usuarios/EditarUsuario.vue';
import RegistrarCliente from '../views/Clientes/RegistrarCliente.vue';
import VerClientes from '../views/Clientes/VerClientes.vue';
import VerCliente from '../views/Clientes/VerCliente.vue';
import AgregarPrenda from '../views/Prendas/AgregarPrenda.vue';

const routes = [
  { path: '/', component: Login },
  {
    path: '/admin',
    component: Admin,
    children: [
      { path: '', component: AdminHome },
      { path: 'home', component: AdminHome },
      { path: 'clientes', component: Clientes },
      { path: 'clientes/registrar', component: RegistrarCliente },
      { path: 'clientes/ver', component: VerClientes },
      { path: 'clientes/ver/:id', component: VerCliente },
      { path: 'clientes/ver/:id/agregar-prenda', name: 'AgregarPrenda', component: AgregarPrenda },
      { path: 'prestamos', component: AdminHome }, // Reemplaza con la vista adecuada
      { path: 'agregar-sucursal', component: Sucursales },
      { path: 'ver-sucursales', component: VerSucursales },
      { path: 'editar-sucursal/:id', component: EditarSucursal },
      { path: 'configuracion', component: AdminHome }, // Reemplaza con la vista adecuada
      { path: 'registrar-usuario', component: RegistrarUsuario },
      { path: 'ver-usuarios', component: VerUsuarios },
      { path: 'editar-usuario/:id', component: EditarUsuario }
    ]
  },
  { path: '/clientes', component: Clientes }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
