import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'flowbite';
import Flowbite from 'flowbite-vue'


const app = createApp(App);

app.use(router);
app.use(store);
app.use(Flowbite);
app.mount('#app');

