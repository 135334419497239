<template>
    <div v-if="cliente" class="ver-cliente">
      <h2 class="page-title">Detalles del Cliente</h2>
      <div class="top-section">
        <div class="cliente-info-card">
          <div class="avatar" v-if="presignedAvatarUrl">
            <img :src="presignedAvatarUrl" alt="Avatar">
          </div>
          <div class="info">
            <p><strong>Número de Cliente:</strong> {{ cliente.id }}</p>
            <p><strong>Nombre:</strong> {{ cliente.nombre }}</p>
            <p><strong>Apellido:</strong> {{ cliente.apellido }}</p>
            <p><strong>Dirección:</strong> {{ cliente.direccion }}</p>
            <p><strong>Ciudad:</strong> {{ cliente.ciudad }}</p>
            <p><strong>Estado:</strong> {{ cliente.estado }}</p>
            <p><strong>Código Postal:</strong> {{ cliente.zipcode }}</p>
            <p><strong>Teléfono 1:</strong> {{ cliente.telefono1 }}</p>
            <p><strong>Teléfono 2:</strong> {{ cliente.telefono2 }}</p>
            <p><strong>Email:</strong> {{ cliente.email }}</p>
            <button @click="goToEditarPerfil" class="complete-button">Editar perfil</button>
          </div>
        </div>
  
        <div class="box">
          <h3>Prendas</h3>
          <button @click="goToAgregarPrenda" class="add-button">Agregar Nueva Prenda</button>
          <div class="cards">
            <div class="card" v-for="prenda in prendas" :key="prenda.id">
              <p><strong>Prenda:</strong> {{ prenda.prenda }}</p>
              <p><strong>Descripción:</strong> {{ prenda.desc_prenda }}</p>
              <p><strong>Límite de Crédito:</strong> {{ prenda.limite_credito }}</p>
              <button @click="goToEditarPrenda(prenda.id)" class="edit-button">Editar Prenda</button>
              <button @click="goToAgregarPrestamo(prenda.id)" class="important-button">Agregar Préstamo</button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="boxes">
        <div class="box">
          <h3>Préstamos</h3>
          <button @click="goToAgregarPrestamo" class="add-button">Agregar Préstamo</button>
        </div>
        <div class="box" v-if="adicionales.length">
          <h3>Detalles Adicionales del Cliente</h3>
          <p><strong>Número de Licencia:</strong> {{ adicionales[0].numero_licencia }}</p>
          <p><strong>Imagen de Licencia:</strong> <a :href="adicionales[0].imagen_licencia" target="_blank">Ver Licencia</a></p>
          <p><strong>Fecha de Nacimiento:</strong> {{ adicionales[0].fecha_nacimiento }}</p>
        </div>
        <div class="box" v-else>
          <h3>Detalles Adicionales del Cliente</h3>
          <button @click="completeData('adicionales')" class="complete-button">Completar Datos</button>
        </div>
        <div class="box" v-if="avales.length">
          <h3>Aval del Cliente</h3>
          <p><strong>Datos de Aval:</strong> {{ avales[0].data }}</p>
        </div>
        <div class="box" v-else>
          <h3>Aval del Cliente</h3>
          <button @click="completeData('avales')" class="complete-button">Completar Datos</button>
        </div>
        <div class="box" v-if="bancos.length">
          <h3>Información Bancaria</h3>
          <p><strong>Datos Bancarios:</strong> {{ bancos[0].data }}</p>
        </div>
        <div class="box" v-else>
          <h3>Información Bancaria</h3>
          <button @click="completeData('bancos')" class="complete-button">Completar Datos</button>
        </div>
        <div class="box" v-if="empleos.length">
          <h3>Información de Empleo</h3>
          <p><strong>Datos de Empleo:</strong> {{ empleos[0].data }}</p>
        </div>
        <div class="box" v-else>
          <h3>Información de Empleo</h3>
          <button @click="completeData('empleos')" class="complete-button">Completar Datos</button>
        </div>
        <div class="box" v-if="referencias.length">
          <h3>Referencias del Cliente</h3>
          <p><strong>Datos de Referencia:</strong> {{ referencias[0].data }}</p>
        </div>
        <div class="box" v-else>
          <h3>Referencias del Cliente</h3>
          <button @click="completeData('referencias')" class="complete-button">Completar Datos</button>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <div v-else class="loading">
      Cargando...
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        cliente: null,
        adicionales: [],
        avales: [],
        bancos: [],
        empleos: [],
        referencias: [],
        prendas: [],
        presignedAvatarUrl: '',
      };
    },
    async created() {
      await this.loadEnvVariables();
      await this.fetchCliente();
      this.fetchAdicionales();
      this.fetchAvales();
      this.fetchBancos();
      this.fetchEmpleos();
      this.fetchReferencias();
      this.fetchPrendas();
    },
    methods: {
      async loadEnvVariables() {
        while (!process.env.VUE_APP_API_BASE_URL) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      },
      async fetchCliente() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.cliente = response.data;
          await this.fetchPresignedAvatarUrl(clienteId);
        } catch (error) {
          console.error('Error fetching cliente:', error);
        }
      },
      async fetchPresignedAvatarUrl(clienteId) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/clientes/${clienteId}/presigned-url`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.presignedAvatarUrl = response.data.url;
        } catch (error) {
          console.error('Error fetching presigned URL for avatar:', error);
        }
      },
      async fetchAdicionales() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/adicionales`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.adicionales = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.adicionales = [];
          } else {
            console.error('Error fetching adicionales:', error);
          }
        }
      },
      async fetchAvales() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/avales`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.avales = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.avales = [];
          } else {
            console.error('Error fetching avales:', error);
          }
        }
      },
      async fetchBancos() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/bancos`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.bancos = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.bancos = [];
          } else {
            console.error('Error fetching bancos:', error);
          }
        }
      },
      async fetchEmpleos() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/empleos`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.empleos = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.empleos = [];
          } else {
            console.error('Error fetching empleos:', error);
          }
        }
      },
      async fetchReferencias() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/referencias`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.referencias = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.referencias = [];
          } else {
            console.error('Error fetching referencias:', error);
          }
        }
      },
      async fetchPrendas() {
        const clienteId = this.$route.params.id;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-cliente/${clienteId}/prendas`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.prendas = response.data;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.prendas = [];
          } else {
            console.error('Error fetching prendas:', error);
          }
        }
      },
      completeData(type) {
        alert('Completar datos para ' + type);
      },
      goToAgregarPrenda() {
        this.$router.push({ name: 'AgregarPrenda', params: { id: this.cliente.id, sucursal_id: this.cliente.sucursal_id, user_id: this.cliente.user_id } });
      },
      goToEditarPrenda(prendaId) {
        this.$router.push({ name: 'EditarPrenda', params: { id: this.cliente.id, prendaId } });
      },
      goToAgregarPrestamo(prendaId) {
        this.$router.push({ name: 'AgregarPrestamo', params: { id: this.cliente.id, prendaId } });
      },
      goToEditarPerfil() {
        // Implementar la funcionalidad de editar perfil según sea necesario
        alert('Funcionalidad de editar perfil no implementada');
      }
    }
  };
  </script>
  
  <style>
  .loading {
    text-align: center;
    font-size: 24px;
    padding: 20px;
  }
  </style>
  