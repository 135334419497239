<template>
    <div class="register-cliente">
      <h2>Registrar Cliente</h2>
      <form @submit.prevent="submitForm" class="form-container">
        <div>
          <label>Nombre:</label>
          <input type="text" v-model="nombre" required>
        </div>
        <div>
          <label>Apellido:</label>
          <input type="text" v-model="apellido" required>
        </div>
        <div>
          <label>Dirección:</label>
          <input type="text" v-model="direccion" required>
        </div>
        <div>
          <label>ZIP Code:</label>
          <div class="zipcode-container">
            <input type="text" v-model="zipcode" required>
            <button type="button" @click="validateZipcode" class="complete-button">Validar Código Postal</button>
          </div>
          <p v-if="zipcodeError" class="error-message">{{ zipcodeError }}</p>
        </div>
        <div>
          <label>Ciudad:</label>
          <input type="text" v-model="ciudad" :disabled="!ciudadEnabled" required>
        </div>
        <div>
          <label>Estado:</label>
          <select v-model="estado" required>
            <option v-for="state in estados" :value="state" :selected="state === estado">{{ state }}</option>
          </select>
        </div>
        <div>
          <label>Teléfono 1:</label>
          <input type="text" v-model="telefono1" required>
        </div>
        <div>
          <label>Teléfono 2:</label>
          <input type="text" v-model="telefono2">
        </div>
        <div>
          <label>Email:</label>
          <input type="email" v-model="email" required>
        </div>
        <div>
          <label>Sucursal:</label>
          <select v-model="sucursal_id" required>
            <option v-for="sucursal in sucursales" :value="sucursal.id">{{ sucursal.nombre_sucursal }}</option>
          </select>
        </div>
        <button type="submit" class="submit-button">Registrar Cliente</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        nombre: '',
        apellido: '',
        direccion: '',
        ciudad: '',
        estado: '',
        zipcode: '',
        telefono1: '',
        telefono2: '',
        email: '',
        sucursal_id: '',
        sucursales: [],
        estados: [
          'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
          'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
          'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
          'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
          'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
          'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
          'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'District of Columbia',
          'American Samoa', 'Guam', 'Northern Mariana Islands', 'Puerto Rico', 'United States Virgin Islands'
        ],
        ciudadEnabled: false,
        zipcodeError: '',
        errorMessage: ''
      };
    },
    created() {
      this.fetchSucursales();
    },
    methods: {
      async fetchSucursales() {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-sucursales`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.sucursales = response.data;
        } catch (error) {
          console.error('Error fetching sucursales:', error);
        }
      },
      async validateZipcode() {
        this.zipcodeError = '';
        this.ciudad = '';
        this.estado = '';
        this.ciudadEnabled = false;
  
        try {
          const response = await axios.get(`https://usa-zip-codes-e41ab29d58f0.herokuapp.com/api/zipcode/${this.zipcode}`);
          const data = response.data;
  
          if (data && data.STATE_COMPLETE && data.PO_NAME) {
            this.ciudad = data.PO_NAME;
            this.estado = data.STATE_COMPLETE;
            this.ciudadEnabled = true;
          } else {
            this.zipcodeError = 'ZIP Code no válido: No se encontró información';
          }
        } catch (error) {
          console.error('Error validating ZIP Code:', error);
          this.zipcodeError = 'ZIP Code no válido: ' + (error.response?.data?.message || error.message || 'Network Error');
        }
      },
      async submitForm() {
        this.errorMessage = '';
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/registrar-cliente`, {
            nombre: this.nombre,
            apellido: this.apellido,
            direccion: this.direccion,
            ciudad: this.ciudad,
            estado: this.estado,
            zipcode: this.zipcode,
            telefono1: this.telefono1,
            telefono2: this.telefono2 || '0000000000',
            email: this.email,
            sucursal_id: this.sucursal_id,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const clienteId = response.data.cliente.id;
          this.$router.push(`/admin/clientes/ver/${clienteId}`);
        } catch (error) {
          console.error('Error registrando cliente:', error);
          this.errorMessage = 'Error registrando cliente: ' + (error.response?.data?.message || error.message);
        }
      }
    }
  };
  </script>
  
  <style>
  .zipcode-container {
    display: flex;
    align-items: center;
  }
  
  .zipcode-container input {
    flex: 1;
  }
  
  .zipcode-container button {
    margin-left: 10px;
  }
  </style>
  