<template>
  <div class="login-box">
    <div class="login-form">
      <h2>Bienvenidos</h2>
      <form @submit.prevent="submitForm">
        <input type="text" v-model="email" placeholder="Email" required>
        <input type="password" v-model="password" placeholder="Password" required>
        <button type="submit" :disabled="isSubmitting">Iniciar Sesión</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>
    <div class="login-logo">
      <img src="images/loguito2.png" alt="Logo">
    </div>
    <div v-if="isSubmitting" class="loading-overlay">
      <p>Iniciando Sesión... ESPERE</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      isSubmitting: false
    };
  },
  methods: {
    async submitForm() {
      this.errorMessage = ''; // Reset error message
      this.isSubmitting = true; // Set loading state
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/login`, {
          email: this.email,
          password: this.password
        });

        const token = response.data.token;
        localStorage.setItem('token', token);

        if (response.data.logincorrecto === 'Autorizado') {
          this.getUserDetails(token);
        } else {
          this.errorMessage = 'Login failed: ' + response.data.message;
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.errorMessage = `Error: ${error.response.data.message}`;
        } else if (error.request) {
          // The request was made but no response was received
          this.errorMessage = 'No response received from the server. Please try again later.';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.errorMessage = `Error: ${error.message}`;
        }
        console.error('Login error:', error);
      }
    },
    async getUserDetails(token) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const user = response.data.user;
        if (user.userlevel == 1000) {
          this.$router.push('/admin');
        } else if (user.userlevel == 1001) {
          this.$router.push('/clientes');
        } else {
          this.errorMessage = 'Unauthorized user level';
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        this.errorMessage = 'Failed to fetch user details';
      }
    }
  }
};
</script>
  
  <style src="../../public/styles/styles.css"></style>
  
    