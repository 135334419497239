<template>
    <div class="admin-home">
      <div class="search-box">
        <h2>Buscar Cliente o Contrato</h2>
        <input type="text" v-model="searchQuery" @input="fetchSuggestions" placeholder="Teclee el número de contrato o nombre de cliente">
        <table v-if="suggestions.length" class="suggestions-table">
          <thead>
            <tr>
              <th>ID Cliente</th>
              <th>Nombre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="suggestion in suggestions" :key="suggestion.id">
              <td>{{ suggestion.id }}</td>
              <td>{{ suggestion.nombre }} {{ suggestion.apellido }}</td>
              <td><button @click="selectSuggestion(suggestion)" class="view-client-button">Ver Cliente</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AdminHome',
    data() {
      return {
        searchQuery: '',
        suggestions: []
      };
    },
    methods: {
      async fetchSuggestions() {
        if (this.searchQuery.length > 2) {
          try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/buscar-clientes`, {
              params: { query: this.searchQuery },
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            this.suggestions = response.data;
          } catch (error) {
            console.error('Error fetching suggestions:', error);
          }
        } else {
          this.suggestions = [];
        }
      },
      selectSuggestion(suggestion) {
        this.$router.push(`/admin/clientes/ver/${suggestion.id}`);
      }
    }
  };
  </script>
  
  <style src="../../../public/styles/styles.css"></style>
  
  