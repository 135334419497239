<template>
    <div class="ver-clientes">
      <h2>Lista de Clientes</h2>
      <div class="controls">
        <label for="perPage">Clientes por página:</label>
        <select v-model="perPage" @change="updatePagination">
          <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Dirección</th>
            <th>Ciudad</th>
            <th>Estado</th>
            <th>Código Postal</th>
            <th>Teléfono 1</th>
            <th>Teléfono 2</th>
            <th>Email</th>
            <th>Sucursal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cliente in paginatedClientes" :key="cliente.id" @click="goToCliente(cliente.id)">
            <td>{{ cliente.id }}</td>
            <td>{{ cliente.nombre }}</td>
            <td>{{ cliente.apellido }}</td>
            <td>{{ cliente.direccion }}</td>
            <td>{{ cliente.ciudad }}</td>
            <td>{{ cliente.estado }}</td>
            <td>{{ cliente.zipcode }}</td>
            <td>{{ cliente.telefono1 }}</td>
            <td>{{ cliente.telefono2 }}</td>
            <td>{{ cliente.email }}</td>
            <td>{{ cliente.sucursal.nombre_sucursal }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="changePage(page)" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          {{ page }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        clientes: [],
        perPageOptions: [10, 20, 50, 100],
        perPage: 10,
        currentPage: 1,
        totalItems: 0,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.perPage);
      },
      paginatedClientes() {
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return this.clientes.slice(start, end);
      }
    },
    created() {
      this.fetchClientes();
    },
    methods: {
      async fetchClientes() {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/ver-clientes`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          this.clientes = response.data.data;
          this.totalItems = response.data.total;
          this.currentPage = response.data.current_page;
        } catch (error) {
          console.error('Error fetching clientes:', error);
        }
      },
      updatePagination() {
        this.currentPage = 1;
        this.fetchClientes();
      },
      changePage(page) {
        this.currentPage = page;
        this.fetchClientes();
      },
      goToCliente(clienteId) {
        this.$router.push(`/admin/clientes/ver/${clienteId}`);
      }
    }
  };
  </script>
  