<template>
    <div id="admin-dashboard">
      <div class="top-bar">
        <img src="/images/loguito2.png" alt="Logo">
      </div>
      <div class="main-content">
        <div class="side-menu">
          <ul>
            <li @click="goTo('home')">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/home.png" alt="Inicio Icon">
              Inicio
            </li>
            <li class="menu-item-dropdown">
              <img src="https://img.icons8.com/ios-filled/50/ffffff/user-group-man-man.png" alt="Clientes Icon">
              Clientes
              <ul class="sub-menu">
                <li @click="goTo('clientes/registrar')">Registrar Cliente</li>
                <li @click="goTo('clientes/ver')">Ver Clientes</li>
              </ul>
            </li>
            <li @click="goTo('prestamos')">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/money.png" alt="Préstamos Icon">
              Préstamos
            </li>
            <li class="menu-item-dropdown">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/building.png" alt="Sucursales Icon">
              Sucursales
              <ul class="sub-menu">
                <li @click="goTo('agregar-sucursal')">Agregar Sucursal</li>
                <li @click="goTo('ver-sucursales')">Ver Sucursales</li>
              </ul>
            </li>
            <li @click="goTo('configuracion')">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/settings.png" alt="Configuración Icon">
              Configuración
            </li>
            <li class="menu-item-dropdown">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/user-group-man-man.png" alt="Usuarios Icon">
              Usuarios
              <ul class="sub-menu">
                <li @click="goTo('registrar-usuario')">Registrar Usuarios</li>
                <li @click="goTo('ver-usuarios')">Ver Usuarios</li>
              </ul>
            </li>
            <li @click="logout">
              <img src="https://img.icons8.com/ios-filled/35/ffffff/logout-rounded.png" alt="Salir Icon">
              Salir
            </li>
          </ul>
        </div>
        <div class="content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Admin',
    methods: {
      goTo(route) {
        this.$router.push(`/admin/${route}`);
      },
      logout() {
        localStorage.removeItem('token');
        this.$router.push('/');
      }
    }
  };
  </script>
  
  <style src="../../../public/styles/styles.css"></style>
  